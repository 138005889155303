<script>
import { SuawParagraph } from "@/components";
import { GetChallengesForAdmin } from "./challengeListOperations.gql";

export default {
  name: "ChallengeList",

  components: {
    SuawParagraph
  },

  data() {
    return {
      error: null,
      resultChallenges: []
    };
  },

  apollo: {
    resultChallenges: {
      query: GetChallengesForAdmin,
      fetchPolicy: "network-only"
    }
  },

  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    },
    clickChallenge(challengeId) {
      this.$router.push({
        name: "ChallengeManage",
        query: { id: challengeId }
      });
    }
  }
};
</script>

<template>
  <div class="challenge-list">
    <div class="header">
      <h1>Challenges Admin</h1>
      <router-link :to="{ name: 'ChallengeManage' }" class="create-button">
        Create Challenge
      </router-link>
    </div>

    <div v-if="$apollo.loading" class="loading">
      Loading challenges...
    </div>
    <div v-else-if="error" class="error">
      {{ error }}
    </div>
    <div v-else class="challenges">
      <div v-for="challenge in resultChallenges" :key="challenge.id" class="challenge-item" @click="clickChallenge(challenge.id)">
        <div class="challenge-header">
          <h2>{{ challenge.title }}</h2>
          <span :class="['status', challenge.is_draft ? 'draft' : 'published']">
            {{ challenge.is_draft ? "Draft" : "Published" }}
          </span>
        </div>
        <div class="challenge-description">
          <SuawParagraph :text="challenge.body_json" />
        </div>
        <div class="challenge-date">Available at: {{ formatDate(challenge.available_at) }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.challenge-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.create-button {
  padding: 0.75rem 1.5rem;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.create-button:hover {
  background-color: #45a049;
}

.loading,
.error {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  color: #dc3545;
}

.challenge-item {
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.challenge-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.challenge-header h2 {
  margin: 0;
  font-size: 1.25rem;
}

.status {
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.875rem;
}

.status.draft {
  background-color: #ffd700;
  color: #000;
}

.status.published {
  background-color: #4caf50;
  color: white;
}

.challenge-date {
  color: #666;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.challenge-description {
  color: #333;
}
</style>
